@import "angular-calendar/css/angular-calendar.css";
$modal-z: 1000;
@import "bulma/bulma";
@import "bulma-pageloader";
@import "@fortawesome/fontawesome-free/css/all.css";

mwl-calendar-month-cell:hover {
  cursor: pointer;
}

mwl-calendar-month-cell.cal-disabled {
  cursor: not-allowed !important;
  background-color: #eee;
}

.cal-disabled .cal-day-number {
  opacity: .1;
}

html {
  overflow-y: auto;
}
